import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { Facebook } from "@awesome-cordova-plugins/facebook";

Facebook.setApplicationId("3210843972465574");

import { IonicVue } from "@ionic/vue";

import "./theme/global.css";

/* Swiper  */
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/keyboard";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/zoom";
import "@ionic/vue/css/ionic-swiper.css";

const app = createApp(App).use(IonicVue).use(router);

router.isReady().then(() => {
	app.mount("#app");
});
