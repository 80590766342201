const removeMask = (form: any, allowed: string[]) => {
	for (const key in form) {
		if (allowed.includes(key)) {
			form[key] = String(form[key]).replace(/[^a-z0-9]/gi, "");
		}
	}
	return form;
};

const removeStringMask = (string: string) => {
	return String(string).replace(/[^a-z0-9]/gi, "");
};

const formatDate = (data: any) => {
	const date = new Date(data);
	date.setTime(date.getTime() + 3 * 60 * 60 * 1000);
	return date.toLocaleDateString("pt-br");
};

const formatDateTime = (data: any) => {
	const date = new Date(data).toLocaleDateString("pt-br");
	const time = new Date(data).toLocaleTimeString("pt-br");
	return `${date} ${time}`;
};

const filterCnpj = (string: string) => {
	return string.replace(
		/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
		"$1.$2.$3/$4-$5"
	);
};

const cpf = (string: string) => {
	return string.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};
const cep = (string: string) => {
	return string.replace(/(\d{5})(\d{3})/, "$1-$2");
};
const phone = (phone: string | number) => {
	const withoutMask = String(removeStringMask(String(phone)));
	if (withoutMask.length === 10)
		return withoutMask.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");

	return withoutMask.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4");
};

const formattedIsMoney = (value: string) => {
	const newNUmber = value.replace("R$", "").replaceAll(",", "");

	return Number(newNUmber).toLocaleString("pt-BR", {
		style: "currency",
		currency: "BRL",
	});
};

export {
	removeMask,
	removeStringMask,
	formatDate,
	formatDateTime,
	filterCnpj,
	cpf,
	cep,
	phone,
	formattedIsMoney,
};
