import * as yup from "yup";
export const step0 = yup.object().shape({});

export const step1 = yup.object().shape({
	year: yup
		.string()
		.trim()
		.length(4, "O ano deve conter 4 caracteres.")
		.required("O campo ano é obrigatório"),
	grossRevenue: yup
		.string()
		.trim()
		.required("O campo receita bruta é obrigatório."),
	hasEmployee: yup.boolean().required(),
});
