import axios from "axios";

const DEFAULT_ERROR_MESSAGE = "Internal Server Error";

const client = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
});

client.interceptors.request.use(
	(config) => {
		const accessToken = localStorage.getItem("accessToken");

		if (accessToken) {
			config.headers = {
				...config.headers,
				authorization: `Bearer ${accessToken}`,
			};
		}

		return config;
	},
	(error) => Promise.reject(error)
);

client.interceptors.response.use(
	(response) => response,
	(error) => {
		let errorMessage = DEFAULT_ERROR_MESSAGE;

		if (error.response?.data?.error?.message)
			errorMessage = error.response.data.error.message;

		return Promise.reject({
			message: errorMessage,
			status: error.response?.status || 500,
		});
	}
);

export default {
	login: async (body: { email: string; password: string }) => {
		const { data } = await client.post("/login", body);
		return data;
	},

	register: async (body: any) => {
		const { data } = await client.post("/register", body);
		return data;
	},

	getServices: async () => {
		const { data } = await client.get("/services");
		return data;
	},

	me: async (): Promise<{ me: any }> => {
		const { data } = await client.get("/me");
		return data;
	},

	sendPushNotificationToken: async (body: {
		token: string;
		device?: any;
	}) => {
		await client.put("/push-notification/token", body);
	},

	getCnaes: async () => {
		const { data } = await client.get("/cnaes");
		return data;
	},

	addMei: async (body: any) => {
		const { data } = await client.post("/mei", body);
		return data;
	},

	createOrder: async (body: any) => {
		const { data } = await client.post("/order", body);
		return data;
	},

	createPixOrderPayment: async (body: any) => {
		const { data } = await client.post("/payment/pix", body);
		return data;
	},

	createCardOrderPayment: async (body: any) => {
		const { data } = await client.post("/payment/card", body);
		return data;
	},
	getOrderPayments: async () => {
		const { data } = await client.get("/order");
		return data;
	},
	findOrderPayment: async (orderPaymentId: string) => {
		const { data } = await client.get(`/payment/${orderPaymentId}`);
		return data;
	},
	getContacts: async (body: any) => {
		const { data } = await client.get("/contacts", body);
		return data;
	},
	getContactFind: async (id: any) => {
		const { data } = await client.get(`/contacts/${id}`);
		return data;
	},
	createContact: async (body: any) => {
		const { data } = await client.post("/contacts", body);
		return data;
	},
	updateContact: async (id: any, body: any) => {
		const { data } = await client.put(`/contacts/${id}`, body);
		return data;
	},
	deleteContact: async (id: any) => {
		const { data } = await client.delete(`/contacts/${id}`);
		return data;
	},
	getBanners: async () => {
		const { data } = await client.get("/banner");
		return data;
	},
	loadCnpj: async (data: any): Promise<void> => {
		await client.post("/mei", data);
	},
	deleteOrder: async (orderId: string): Promise<void> => {
		await client.delete(`/order/${orderId}`);
	},
	getDas: async () => {
		const { data } = await client.get("/das");
		return data;
	},
	getDasYear: async (year: any) => {
		const { data } = await client.get(`/das/${year}`);
		return data;
	},
	getDasn: async () => {
		const { data } = await client.get("/dasn");
		return data;
	},
	updateDas: async () => {
		await client.get("/update-das");
	},
	dasUpdateStatus: async () => {
		const { data } = await client.get("/das-update-status");
		return data;
	},
	sendDasToMail: async (body: any) => {
		const { data } = await client.post("/das/send-to-mail", body);
		return data;
	},
	getLearningContents: async (params: any) => {
		const { data } = await client.get(
			`/learning-content?category=${params.category}`
		);
		return data;
	},
	getLearningCategories: async () => {
		const { data } = await client.get(`/learning-content/categories`);
		return data;
	},
	editUser: async (body: any) => {
		await client.put("/user/update", body);
	},
	changePassword: async (body: any) => {
		await client.put("/user/password", body);
	},
	getBenefits: async () => {
		const { data } = await client.get("/benefit");
		return data;
	},
	getHomeResume: async () => {
		const { data } = await client.get("/home-resume");
		return data;
	},
	getNotifications: async () => {
		const { data } = await client.get("/notifications");
		return data;
	},
	readNotifications: async () => {
		const { data } = await client.get("/notifications/read");
		return data;
	},
	sendRecoverPassword: async (email: any) => {
		const { data } = await client.post("/recover-password", { email });
		return data;
	},
	getStories: async () => {
		const { data } = await client.get("/story");
		return data;
	},
	getSolutions: async () => {
		const { data } = await client.get("/solutions");
		return data;
	},
	getCards: async () => {
		const { data } = await client.get("/card");
		return data;
	},
	destroyCard: async (token: string) => {
		const { data } = await client.delete(`/card/${token}`);
		return data;
	},
	getInternalContactsSort: async (type: any) => {
		const { data } = await client.get(
			`/internal-contacts/sort?type=${type}`
		);
		return data;
	},
	getInternalEmailsSort: async (type: any) => {
		const { data } = await client.get(`/internal-emails/sort?type=${type}`);
		return data;
	},
};
