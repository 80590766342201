import api from "../services/api/api";
import { ref } from "vue";
import { removeMask } from "@/helpers/filters";

const form = ref<any>({});

export const useServiceForm = () => {
	const setFormData = (payload: typeof form) => {
		const p = Object.fromEntries(
			Object.entries(payload).filter(([_, value]: any) => {
				return value !== null && value !== undefined && value !== "";
			})
		);
		form.value = { ...form.value, ...p };
	};

	const clearForm = () => {
		form.value = {};
	};

	const createRegistrationRegularizationOrder = async (service: any) => {
		const f = form.value;
		const body = {
			cpf: f.cpf,
			name: f.name,
			phone: f.phone,
			gender: f.gender,
			ufBirth: f.ufBirth,
			docType: f.docType,
			docData: f.docData,
			uf: f.uf,
			cep: f.cep,
			city: f.city,
			street: f.street,
			number: f.number,
			complement: f.complement,
			neighborhood: f.neighborhood,
			birthDate: f.birthDate,
			motherName: f.motherName,
			citizenship: f.citizenship,
		};
		const allowedToFilter = [
			"cpf",
			"cep",
			"phone",
			"voterRegistrationCard",
		];
		return api.createOrder({
			serviceId: service.value.id,
			serviceCode: service.value.code,
			requestData: removeMask(body, allowedToFilter),
		});
	};
	return {
		setFormData,
		clearForm,
		createRegistrationRegularizationOrder,
		form,
	};
};
