import api from "@/services/api/api";
import { ref } from "vue";

const openStory = ref<any>(false);
const stories = ref<any>();
const defaultTiming = 5000;

const loadStories = async (): Promise<void> => {
	const response = await api.getStories();
	stories.value = response.stories.map((story: any) => {
		return { ...story, width: 0, visualized: false, timing: defaultTiming };
	});
};

export const useStory = () => {
	return {
		stories,
		openStory,
		loadStories,
	};
};
