import { ref } from "vue";
import api from "../services/api/api";

export interface IService {
	id: string;
	name?: string;
	description: string;
	amount: number;
}

const services = ref<any>(null);
const service = ref<any>(null);

const setServices = (payload: any) => {
	services.value = payload;
};

const getByServiceCode = async (serviceCode: string) => {
	if (services.value) {
		service.value = services.value.filter((service: any) => {
			return service.code === serviceCode;
		})[0];
	} else {
		await loadServices();
		await getByServiceCode(serviceCode);
	}
};

const loadServices = async () => {
	const data = await api.getServices();
	setServices(data.services);
};

export const useService = () => {
	return { services, service, loadServices, getByServiceCode };
};
