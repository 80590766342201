import {
	ActionPerformed,
	PushNotifications,
	PushNotificationSchema,
} from "@capacitor/push-notifications";
import { changeRoute } from "@/router";
import { changeModal } from "./modalNotification";

export const usePushNotification = () => {
	const onRegistration = (callback: (token: string) => void) =>
		PushNotifications.addListener("registration", (token) => {
			console.info("Registration token: ", token.value);
			callback(token.value);
		});

	const onRegistrationError = (callback: (err: any) => void) =>
		PushNotifications.addListener("registrationError", (err) => {
			console.error("Registration error: ", err.error);
			callback(err);
		});

	const onPushNotificationReceived = (
		callback: (notification: PushNotificationSchema) => void
	) =>
		PushNotifications.addListener(
			"pushNotificationReceived",
			(notification) => {
				console.log("Push notification received: ", notification);
				callback(notification);
				if (notification.data.route == "/openmodal") {
					changeModal(true);
				} else {
					changeRoute(notification.data.route);
				}
			}
		);

	const onPushNotificationActionPerformed = (
		callback: (notification: ActionPerformed) => void
	) => {
		PushNotifications.addListener(
			"pushNotificationActionPerformed",
			(notification) => {
				console.log(
					"Push notification action performed",
					notification.actionId,
					notification.inputValue
				);
				callback(notification);
				if (notification.notification.data.route == "/openmodal") {
					changeModal(true);
				} else {
					changeRoute(notification.notification.data.route);
				}
			}
		);
	};

	const registerNotifications = async () => {
		let permStatus = await PushNotifications.checkPermissions();

		if (permStatus.receive === "prompt") {
			permStatus = await PushNotifications.requestPermissions();
		}

		if (permStatus.receive !== "granted") {
			console.error("User denied permissions!");
		} else {
			await PushNotifications.register();
		}
	};

	const getDeliveredNotifications = async () => {
		const notificationList =
			await PushNotifications.getDeliveredNotifications();
		console.log("delivered notifications", notificationList);
	};

	return {
		getDeliveredNotifications,
		registerNotifications,
		onRegistration,
		onRegistrationError,
		onPushNotificationReceived,
		onPushNotificationActionPerformed,
	};
};
