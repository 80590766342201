import * as yup from "yup";
export const step0 = yup.object().shape({});

export const step1 = yup.object().shape({
	// irpf: yup.string().trim(),
	cnpj: yup.string().trim().required("O campo CNPJ é obrigatório."),
	govPassword: yup.string().trim().required("O campo senha é obrigatório."),
	birthDate: yup
		.date()
		.typeError("Data de nascimento inválida.")
		.transform((value, originalValue, context) => {
			if (context.isType(value)) return value;
			const dateParts = originalValue.split("/");
			return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
		})
		.max(new Date(), "Data de nascimento inválida.")
		.required("Data de nascimento é obrigatória."),
	// voterRegistration: yup.boolean(),
	// voterRegistrationCard: yup.string().when("voterRegistration", {
	// 	is: false,
	// 	then: yup.string().required(),
	// }),
	// name: yup
	// 	.string()
	// 	.when("voterRegistration", { is: true, then: yup.string().required() }),
	motherName: yup.string().required("Nome da mãe é obrigatório."),
	// fatherName: yup
	// 	.string()
	// 	.when("voterRegistration", { is: true, then: yup.string().required() }),
});
