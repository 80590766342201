import { App, URLOpenListenerEvent } from "@capacitor/app";

import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw, NavigationGuard } from "vue-router";
import Dashboard from "@/views/dashboard/Dashboard.vue";
import Register from "@/views/auth/Register.vue";
import Services from "@/views/dashboard/Services.vue";
import LowMei from "@/views/dashboard/services/LowMei.vue";
import AnnualStatement from "@/views/dashboard/services/AnnualStatement.vue";
import RouterOutlet from "@/views/dashboard/RouterOutlet.vue";

const loginBeforeEnter = () => {
	const accessToken = localStorage.getItem("accessToken");
	return accessToken ? { name: "dashboard" } : true;
};

const dashboardBeforeEnter = () => {
	const accessToken = localStorage.getItem("accessToken");
	return accessToken ? true : { name: "login" };
};

const checkFromName =
	(redirect: string, fromName?: string[]): NavigationGuard =>
	(_, from) => {
		if (
			!from.name ||
			![...(fromName || []), redirect].includes(from.name as string)
		)
			return { name: redirect };

		return true;
	};

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		redirect: "login",
	},
	{
		name: "login",
		path: "/login",
		beforeEnter: loginBeforeEnter,
		component: () => import("@/views/auth/Login.vue"),
	},
	{
		name: "register",
		path: "/register",
		component: Register,
		redirect: { name: "firstStepRegister" },
		children: [
			{
				name: "firstStepRegister",
				path: "first-step-register",
				component: () =>
					import("@/views/auth/registerSteps/FirstStep.vue"),
			},
			{
				name: "secondStepRegister",
				path: "second-step-register",
				beforeEnter: checkFromName("firstStepRegister", [
					"firstStepRegister",
					"thirdStepRegister",
				]),
				component: () =>
					import("@/views/auth/registerSteps/SecondStep.vue"),
			},
			{
				name: "thirdStepRegister",
				path: "third-step-register",
				beforeEnter: checkFromName("firstStepRegister", [
					"secondStepRegister",
				]),
				component: () =>
					import("@/views/auth/registerSteps/ThirdStep.vue"),
			},
		],
	},
	{
		name: "recoverPassword",
		path: "/recover-password",
		component: () => import("@/views/recoverPassword/RecoverPassword.vue"),
	},
	{
		name: "checkEmail",
		path: "/recover-password/check-email",
		component: () => import("@/views/recoverPassword/CheckEmail.vue"),
	},
	{
		name: "dashboard",
		path: "/",
		component: Dashboard,
		beforeEnter: dashboardBeforeEnter,
		redirect: "/home",
		children: [
			{
				name: "home",
				path: "home",
				component: () => import("@/views/dashboard/Home.vue"),
			},
			{
				name: "das",
				path: "das",
				component: () => import("@/views/dashboard/das/Das.vue"),
			},
			{
				name: "payment-das",
				path: "/das/payment-das/:month",
				component: () => import("@/views/dashboard/das/PaymentDas.vue"),
			},
			{
				name: "solutions",
				path: "solutions",
				component: () => import("@/views/dashboard/Solutions.vue"),
			},
			{
				name: "services",
				path: "services",
				component: Services,
			},
			{
				name: "d-dasn",
				path: "d-dasn",
				component: () => import("@/views/dashboard/Dasn.vue"),
			},
			{
				name: "registrationRegularization",
				path: "registration-regularization",
				component: RouterOutlet,
				redirect: { name: "firstStepRegistrationRegularization" },
				children: [
					{
						name: "firstStepRegistrationRegularization",
						path: "first-step-registration-regularization",
						component: () =>
							import(
								"@/views/dashboard/services/registrationRegularizationSteps/FirstStep.vue"
							),
					},
					{
						name: "secondStepRegistrationRegularization",
						path: "second-step-registration-regularization",
						beforeEnter: checkFromName(
							"firstStepRegistrationRegularization",
							[
								"firstStepRegistrationRegularization",
								"thirdStepRegistrationRegularization",
							]
						),
						component: () =>
							import(
								"@/views/dashboard/services/registrationRegularizationSteps/SecondStep.vue"
							),
					},
					{
						name: "thirdStepRegistrationRegularization",
						path: "third-step-registration-regularization",
						beforeEnter: checkFromName(
							"firstStepRegistrationRegularization",
							["secondStepRegistrationRegularization"]
						),
						component: () =>
							import(
								"@/views/dashboard/services/registrationRegularizationSteps/ThirdStep.vue"
							),
					},
				],
			},
			{
				name: "changeMei",
				path: "change-mei",
				component: RouterOutlet,
				redirect: { name: "firstStepChangeMei" },
				children: [
					{
						name: "firstStepChangeMei",
						path: "first-step-change-mei",
						component: () =>
							import(
								"@/views/dashboard/services/changeMeiSteps/FirstStep.vue"
							),
					},
					{
						name: "secondStepChangeMei",
						path: "second-step-change-mei",
						beforeEnter: checkFromName("firstStepChangeMei", [
							"firstStepChangeMei",
							"thirdStepChangeMei",
						]),
						component: () =>
							import(
								"@/views/dashboard/services/changeMeiSteps/SecondStep.vue"
							),
					},
					{
						name: "thirdStepChangeMei",
						path: "third-step-change-mei",
						beforeEnter: checkFromName("firstStepChangeMei", [
							"secondStepChangeMei",
						]),
						component: () =>
							import(
								"@/views/dashboard/services/changeMeiSteps/ThirdStep.vue"
							),
					},
				],
			},
			{
				name: "annualStatement",
				path: "annual-statement",
				component: AnnualStatement,
			},
			{
				name: "lowMei",
				path: "low-mei",
				component: LowMei,
			},
			{
				name: "createMei",
				path: "create-mei",
				component: RouterOutlet,
				redirect: { name: "firstStepCreateMei" },
				children: [
					{
						name: "firstStepCreateMei",
						path: "first-step-create-mei",
						component: () =>
							import(
								"@/views/dashboard/services/createMeiSteps/FirstStep.vue"
							),
					},
					{
						name: "secondStepCreateMei",
						path: "second-step-create-mei",
						beforeEnter: checkFromName("firstStepCreateMei", [
							"firstStepCreateMei",
							"thirdStepCreateMei",
						]),
						component: () =>
							import(
								"@/views/dashboard/services/createMeiSteps/SecondStep.vue"
							),
					},
					{
						name: "thirdStepCreateMei",
						path: "third-step-create-mei",
						beforeEnter: checkFromName("firstStepCreateMei", [
							"secondStepCreateMei",
							"fourthStepCreateMei",
						]),
						component: () =>
							import(
								"@/views/dashboard/services/createMeiSteps/ThirdStep.vue"
							),
					},
					{
						name: "fourthStepCreateMei",
						path: "fourth-step-create-mei",
						beforeEnter: checkFromName("firstStepCreateMei", [
							"thirdStepCreateMei",
						]),
						component: () =>
							import(
								"@/views/dashboard/services/createMeiSteps/FourthStep.vue"
							),
					},
				],
			},
			{
				name: "payments",
				path: "/payments/:id",
				component: () => import("@/views/dashboard/Payments.vue"),
			},
			{
				name: "orders",
				path: "/orders",
				component: () => import("@/views/dashboard/Orders.vue"),
			},
			{
				name: "contacts",
				path: "/contacts",
				component: () =>
					import("@/views/dashboard/contacts/Contacts.vue"),
			},
			{
				name: "loadCnpj",
				path: "/load-cnpj/:nextRoute?",
				component: () => import("@/views/dashboard/LoadCnpj.vue"),
			},
			{
				name: "createContact",
				path: "/contacts/create-contact",
				component: () =>
					import("@/views/dashboard/contacts/CreateContact.vue"),
			},
			{
				name: "editContact",
				path: "/contacts/edit-contact/:id",
				component: () =>
					import("@/views/dashboard/contacts/EditContact.vue"),
			},
			{
				name: "contact",
				path: "/contacts/see-contact/:id",
				component: () =>
					import("@/views/dashboard/contacts/SeeContact.vue"),
			},
			{
				name: "cnpjCard",
				path: "/cnpj-card",
				component: () => import("@/views/dashboard/CnpjCard.vue"),
			},
			{
				name: "learnIt",
				path: "/learn-it",
				component: () =>
					import("@/views/dashboard/learnIt/Categories.vue"),
			},
			{
				name: "learning-content",
				path: "/learn-it/category/:id",
				component: () =>
					import("@/views/dashboard/learnIt/LearningContent.vue"),
			},
			{
				name: "notifications",
				path: "notifications",
				component: () => import("@/views/dashboard/RouterOutlet.vue"),
				children: [
					{
						name: "allNotifications",
						path: "",
						component: () =>
							import(
								"@/views/dashboard/notifications/Notifications.vue"
							),
					},
					{
						name: "showNotifications",
						path: "show/:id",
						component: () =>
							import(
								"@/views/dashboard/notifications/ShowNotification.vue"
							),
					},
				],
			},
			{
				name: "help",
				path: "/help",
				component: () => import("@/views/dashboard/Help.vue"),
			},
			{
				name: "profile",
				path: "profile",
				component: RouterOutlet,
				redirect: { name: "editProfile" },
				children: [
					{
						name: "editProfile",
						path: "edit",
						component: () =>
							import("@/views/dashboard/profile/EditProfile.vue"),
					},
					{
						name: "changePassword",
						path: "change-password",
						component: () =>
							import(
								"@/views/dashboard/profile/ChangePassword.vue"
							),
					},
				],
			},
			{
				name: "benefits",
				path: "benefits",
				component: RouterOutlet,
				redirect: { name: "benefitsMainPage" },
				children: [
					{
						name: "benefitsMainPage",
						path: "",
						component: () =>
							import("@/views/dashboard/benefits/Benefits.vue"),
					},
					{
						name: "benefitDetails",
						path: "details/:benefit",
						component: () =>
							import(
								"@/views/dashboard/benefits/BenefitDetails.vue"
							),
					},
				],
			},
			{
				name: "terms-of-use",
				path: "/terms-of-use",
				component: () => import("@/views/dashboard/TermsOfUse.vue"),
			},
			{
				name: "create-mei-active",
				path: "/create-mei-active",
				component: () =>
					import(
						"@/views/dashboard/services/activeService/CreateMei.vue"
					),
			},
			{
				name: "change-mei-active",
				path: "/change-mei-active",
				component: () =>
					import(
						"@/views/dashboard/services/activeService/ChangeMei.vue"
					),
			},
			{
				name: "low-mei-active",
				path: "/low-mei-active",
				component: () =>
					import(
						"@/views/dashboard/services/activeService/LowMei.vue"
					),
			},
			{
				name: "annual-statement-active",
				path: "/annual-statement-active",
				component: () =>
					import(
						"@/views/dashboard/services/activeService/AnnualStatement.vue"
					),
			},
			{
				name: "regularization-cpf-active",
				path: "/regularization-cpf-active",
				component: () =>
					import(
						"@/views/dashboard/services/activeService/RegularizationCpf.vue"
					),
			},
		],
	},
	{ path: "/:pathMatch(.*)*", redirect: "/" },
];

export const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

App.addListener("appUrlOpen", function (event: URLOpenListenerEvent) {
	const slug = event.url.split("app.gerenciamei.com").pop();

	if (slug) {
		router.push({
			path: slug,
		});
	}
});

export const changeRoute = (route: any) => {
	if (route) {
		router.push({
			path: route,
		});
	}
};

export default router;
