export default {
	debug: false,
	masked: false,
	prefix: "R$ ",
	suffix: "",
	thousands: ".",
	decimal: ",",
	precision: 2,
	disableNegative: true,
	disabled: false,
	min: null,
	max: null,
	allowBlank: true,
	modelModifiers: {
		number: false,
	},
};
