import { ref } from "vue";
import { sleep } from "@/helpers/promise";
import api from "../services/api/api";

export interface IMei {
	id?: string;
	cnpj?: string;
	type?: string;
	name?: string;
	email?: string;
	phone?: string;
	fantasy?: string;
	mainActivity: string;
	secondaryActivity?: any;
	legalNature: string;
	founded: string;
	capital: any;
	status?: string;
	statusDate?: string;
	statusReason?: string;
	street?: string;
	number?: string;
	complement?: string;
	cep?: string;
	city?: string;
	neighborhood?: string;
	uf?: string;
	fileCnpjCard?: string;
	createdAt?: string;
	updatedAt?: string;
}
export interface IUser {
	name: string;
	email: string;
	cpf: string;
	cnpj: string;
	phone: string;
	mei?: IMei;
	motherName?: string;
	voterRegistrationCard?: any;
	birthDate?: any;
	userAddress?: any;
	activateService?: boolean;
	phonesGerenciaMei?: string;
}

const user = ref<IUser | null>(null);

export const useUser = () => {
	const setUser = (payload: any) => {
		user.value = payload as IUser;
	};

	const loadUser = async () => {
		const { me } = await api.me();

		setUser(me);

		await sleep(500);
	};

	const login = async (form: any) => {
		const { accessToken } = await api.login(form);

		localStorage.setItem("accessToken", accessToken);

		await sleep(500);
	};

	const logout = async () => {
		user.value = null;

		localStorage.removeItem("accessToken");

		await sleep(200);
	};

	return { user, login, logout, setUser, loadUser };
};
