
import { IonApp, IonRouterOutlet } from "@ionic/vue";
import { defineComponent } from "vue";
import { FirebaseAnalytics } from "@awesome-cordova-plugins/firebase-analytics";

FirebaseAnalytics.setEnabled(true);

export default defineComponent({
	name: "App",
	components: {
		IonApp,
		IonRouterOutlet,
	},
});
