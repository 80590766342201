import { ref } from "vue";
import api from "../services/api/api";

const orderPayment = ref<any>(null);
const orderPayments = ref<any>(null);

const listOrderPayments = async () => {
	orderPayments.value = await api.getOrderPayments();
};

const checkOrderPayment = async (orderPaymentId: string) => {
	return api.findOrderPayment(orderPaymentId);
};

const payWithPix = async (body: any) => {
	return api.createPixOrderPayment(body);
};

const payWithCard = async (body: any) => {
	let requestBody: any = {
		orderId: body.orderId,
		reference: body.reference,
		visitorID: body.visitorID,
		paymentMethod: body.paymentMethod,
		usingToken: body.usingToken,
		customer: {
			name: body.name,
			email: body.email,
			phone: body.phone,
			identity: body.identity,
			address: {
				complement: body.complement,
				street: body.street,
				number: body.number,
				countryName: "Brasil",
				zipCode: body.zipCode,
				district: body.district,
				cityName: body.cityName,
				stateInitials: body.stateInitials,
			},
		},
	};

	if (!body.usingToken)
		requestBody = {
			paymentObject: {
				requestNumber: body.requestNumber || null,
				validateToken: body.validateToken || null,
				clientIp: body.clientIp || null,
				code3ds: body.code3ds || null,
				holder: body.holder,
				cardNumber: body.cardNumber,
				securityCode: body.securityCode,
				expirationDate: body.expirationDate,
				softDescriptor: "test",
				installmentQuantity: 0,
			},
			canSaveCard: body.canSaveCard,
			...requestBody,
		};
	else
		requestBody = {
			paymentObject: {
				token: body.cardToken,
				softDescriptor: "test",
				installmentQuantity: 0,
			},
			...requestBody,
		};

	return api.createCardOrderPayment(requestBody);
};

export const useOrderPayment = () => {
	return {
		orderPayment,
		orderPayments,
		payWithCard,
		payWithPix,
		listOrderPayments,
		checkOrderPayment,
	};
};
