import api from "@/services/api/api";
import { ref } from "vue";

const notifications = ref<any>();

const unread = ref();

export const useNotifications = () => {
	const getNotifications = async () => {
		const data = await api.getNotifications();
		notifications.value = data.notifications;
		unread.value = data.unread;
	};

	const readNotifications = async () => {
		await api.readNotifications();
	};

	return {
		unread,
		notifications,
		getNotifications,
		readNotifications,
	};
};
