import api from "../services/api/api";
import { useUser } from "./user";

let form = {
	cnpj: "",
	name: "",
	email: "",
	phone: "",
	cpf: "",
	birthDate: "",
	password: "",
};

const clearForm = () => {
	form = {
		cnpj: "",
		name: "",
		email: "",
		phone: "",
		cpf: "",
		birthDate: "",
		password: "",
	};
};

const setFormData = (payload: typeof form) => {
	form = { ...form, ...payload };
};

const register = async () => {
	const { cnpj, name, email, phone, cpf, birthDate, password } = form;
	const birthDateParts = birthDate.split("/");

	await api.register({
		name,
		email,
		cnpj: cnpj.replace(/[^\d]+/g, ""),
		cpf: cpf.replace(/[^\d]+/g, ""),
		phone: phone.replace(/[^\d]+/g, ""),
		birthDate: `${birthDateParts[1]}/${birthDateParts[0]}/${birthDateParts[2]}`,
		password,
	});

	const { login } = useUser();

	await login({ email, password });

	clearForm();
};

export const useRegister = () => {
	return { form, setFormData, register, clearForm };
};
